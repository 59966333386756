<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-form ref="editControlsForm">
          <v-data-table
            disable-pagination
            :headers="headers"
            :items="basketWithIndex"
            hide-default-footer
            class="elevation-2"
            item-key="id"
            :loading="isLoaded"
          >
            <template v-slot:item.actions="props">
              <v-checkbox v-model="props.item.blocked"> </v-checkbox>
            </template>
            <template v-slot:no-data>
              <span class="subheading font-weight-bold"
                >No Controls(s) available</span
              >
            </template>
          </v-data-table>
        </v-form>
      </v-col>
    </v-row>

    <Error v-if="deleteResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Error>
  </v-container>
</template>

<script>
  import { computed, reactive, toRefs, watch } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Error from "@/components/Error/Error";

  export default {
    components: { Error },
    setup() {
      const { intakeLevelSemester } = useActions(["intakeLevelSemester"]);

      const { getters_intakelevelsemester } = useGetters([
        "getters_intakelevelsemester",
      ]);

      const editControlsForm = reactive({
        adminResponse: false,
        isLoaded: true,
        isadmin: true,
        adminPassword: "",
        checkAdmin: "",
        headers: [
          { text: "No.", value: "index" },
          { text: "LEVEL", value: "level.level" },
          { text: "INTAKE", value: "intake.intake" },
          { text: "SEMESTER", value: "semester.semester" },
          { text: "ACTIONS", value: "actions", sortable: false },
        ],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        deleteResponse: false,
        basket: [],
      });

      let { checkAdmin, adminPassword, adminResponse, basket } =
        toRefs(editControlsForm);

      watch(
        () => adminPassword.value,
        () => {
          if (adminPassword.value.length == 0 || adminPassword.value == "") {
            checkAdmin.value = "";
            adminResponse.value = false;
          }
        }
      );

      intakeLevelSemester().then(() => {
        editControlsForm.isLoaded = false;
        basket.value = getters_intakelevelsemester.value;
      });

      const basketWithIndex = computed(() => {
        return basket.value.map((items, index) => ({
          ...items,
          index: index + 1,
        }));
      });

      return {
        ...toRefs(editControlsForm),
        basketWithIndex,
      };
    },
  };
</script>